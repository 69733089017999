import React, { useState, useEffect, useRef } from 'react';

const SingleProduct = ({ product, isArabic, setIsModalOpen }) => {
  const [selectedImage, setSelectedImage] = useState(product.images[0].image);
  const modalRef = useRef(null);

  useEffect(() => {
    // Close modal on outside click
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setIsModalOpen]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const uniqueSizes = [...new Set(product.variations.map(variation => variation.size))];
  const uniqueColors = [...new Set(product.variations.map(variation => variation.color))];

  const handleColorClick = (color) => {
    const selectedVariation = product.variations.find(variation => variation.color === color);
    if (selectedVariation && selectedVariation.image) {
      setSelectedImage(selectedVariation.image);
    } else {
      setSelectedImage(product.images[0].image);
    }
  };

  return (
    <section className="text-gray-700 body-font overflow-hidden bg-white" ref={modalRef}>
      <div className="container px-5 py-8 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <div className="w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h2 className="text-sm title-font text-gray-500 tracking-widest">{isArabic ? product.brand_ar : product.brand}</h2>
            <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">{isArabic ? product.name_ar : product.name}</h1>

            {uniqueColors.length>0&&<div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
              <div className="flex">
                <span className="mr-3">Color</span>
                {uniqueColors.map((color, index) => (
                  <button
                    key={index}
                    className="border-2 border-gray-300 rounded-full w-6 h-6 mr-2 focus:outline-none"
                    style={{ backgroundColor: color, borderColor: color }}
                    onClick={() => handleColorClick(color)}
                  ></button>
                ))}
              </div>
              <div className="flex ml-6 items-center">
                <span className="mr-3">Size</span>
                <div className="relative">
                  <select className="rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-red-500 text-base pl-3 pr-10">
                    {uniqueSizes.map((size, index) => (
                      <option key={index}>{size.toUpperCase()}</option>
                    ))}
                  </select>
                  <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                    <svg 
                      fill="none" 
                      stroke="currentColor" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth="2" 
                      className="w-4 h-4" 
                      viewBox="0 0 24 24">
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>}

            {/* Display all product images */}
            <div className="flex flex-wrap">
              {product.images.map((image, index) => (
                <div key={index} className="w-1/6 py-2 mx-2 cursor-pointer shadow-lg">
                  <img
                    src={image.image}
                    alt={`Thumbnail ${index + 1}`}
                    className="object-cover object-center w-full h-24 rounded"
                    onClick={() => setSelectedImage(image.image)}
                  />
                </div>
              ))}
            </div>

            <div className="w-full mt-6">
              <img 
                alt="ecommerce" 
                className="max-h-[400px] object-fill object-center mx-auto rounded" 
                src={selectedImage} 
              />
            </div>
          </div>
        </div>
        <p className="leading-relaxed mt-4">{isArabic ? product.description_ar : product.description}</p>
      </div>
    </section>
  );
};

export default SingleProduct;
