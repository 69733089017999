import React, { useEffect } from 'react'
import Header from '../components/Header'
import TwoSideSection from '../components/TwoSideSection'
import WhyUs from '../components/Whyus'
import Marquee from '../components/Marquee'
import axios from 'axios';

const logPageVisit = (pageUrl) => {
  axios.get('https://ramsisindustry.com/api/log-visit/', { params: { page_url: pageUrl } })
    .then(response => {
      console.log('Page visit logged:', response.data);
    })
    .catch(error => {
      console.error('Error logging page visit:', error);
    });
};
const Home = ({isArabic, events}) => {
  useEffect(()=>{logPageVisit(window.location.pathname);},[])
  return (
    <div className='w-full'>

        <Header isArabic={isArabic}/>
        <TwoSideSection isArabic={isArabic}/>
        <WhyUs isArabic={isArabic}/>
        <Marquee events={events} isArabic={isArabic}/>
    </div>
  )
}

export default Home