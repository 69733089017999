import React from 'react';
import img1 from '../assets/folderp4.jpg';
import logo from '../assets/Logo.png';
import logo2 from '../assets/falconLogo1.png';
import { WebsiteText } from '../constants/WebsiteText';

const TwoSideSection = ({ isArabic }) => {
  return (
    <div className="w-full bg-secondary bg-center bg-no-repeat">
      <div className="container mx-auto">
        <div className="flex flex-wrap h-full">
          {/* Left Side */}
          <div className="w-full md:w-1/2 px-8 py-14">
            <div className="mb-4">
              <h2 className="text-4xl text-white font-light mb-6">
                {isArabic ? WebsiteText.twoSideSectionHeadingAr : WebsiteText.twoSideSectionHeading}
              </h2>
              <p className="text-white mb-6">
                {isArabic ? WebsiteText.twoSideSectionDescriptionAr : WebsiteText.twoSideSectionDescription}
              </p>
              <div className="grid grid-cols-2 md:grid-cols-4">
                <img
                  src={logo}
                  alt="Stationery"
                  className="w-full max-w-lg mb-6"
                />
                <img
                  src={logo2}
                  alt="Stationery"
                  className="w-full max-w-lg mb-6"
                />
              </div>
              
              <div
                className="inline-block py-3 px-6 bg-primarydark text-white font-bold text-xs uppercase rounded-full hover:bg-primary cursor-pointer transition-colors duration-300"
              >
                {isArabic ? WebsiteText.browseProductsButtonAr : WebsiteText.browseProductsButton}
              </div>
            </div>
          </div>

          {/* Right Side */}
          <div className="w-full md:w-1/2 flex justify-end object-contain">
            <img
              src={img1}
              alt="Cover"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoSideSection;
