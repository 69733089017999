import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import SingleProduct from "./SingleProduct";
import ProductCard2 from "./ProductCard2";
import axios from 'axios';
import { gsap } from "gsap";

const logPageVisit = (pageUrl) => {
  axios.get('https://ramsisindustry.com/api/log-visit/', { params: { page_url: pageUrl } })
    .then(response => {
      console.log('Page visit logged:', response.data);
    })
    .catch(error => {
      console.error('Error logging page visit:', error);
    });
};

const ProductList = ({ productsData, isArabic }) => {
  useEffect(() => {
    logPageVisit(window.location.pathname);
    gsap.from(".product-card", {
      opacity: 0,
      y: 50,
      stagger: 0.2,
      duration: 1,
      ease: "power3.out",
    });
  }, []);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortBy, setSortBy] = useState("Recommended");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);

    gsap.fromTo(".modal", { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1, duration: 0.5, ease: "power3.out" });
  };

  const handleCloseModal = () => {
    gsap.to(".modal", {
      opacity: 0,
      scale: 0.8,
      duration: 0.5,
      ease: "power3.in",
      onComplete: () => {
        setIsModalOpen(false);
        setSelectedProduct(null);
      },
    });
  };

  const handleBrandChange = (brandName) => {
    setSelectedBrands((prevSelectedBrands) =>
      prevSelectedBrands.includes(brandName)
        ? prevSelectedBrands.filter((brand) => brand !== brandName)
        : [...prevSelectedBrands, brandName]
    );
  };

  const handleCategoryChange = (categoryName) => {
    setSelectedCategories((prevSelectedCategories) =>
      prevSelectedCategories.includes(categoryName)
        ? prevSelectedCategories.filter((category) => category !== categoryName)
        : [...prevSelectedCategories, categoryName]
    );
  };

  useEffect(() => {
    let products = [];

    productsData.length > 0 && productsData.forEach((brand) => {
      if (selectedBrands.length === 0 || selectedBrands.includes(brand.name)) {
        brand.categories.forEach((category) => {
          if (selectedCategories.length === 0 || selectedCategories.includes(category.name)) {
            products = products.concat(category.products);
          }
        });
      }
    });

    if (sortBy === "Name") {
      products.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortBy === "Date Added") {
      products.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    setFilteredProducts(products);

    gsap.from(".product-card", {
      opacity: 0,
      y: 50,
      stagger: 0.2,
      duration: 1,
      ease: "power3.out",
    });

  }, [selectedBrands, selectedCategories, sortBy, productsData]);

  return (
    <section className="bg-white mt-20 text-right">
      <div className="container px-6 py-8 mx-auto">
        <div className={`lg:flex ${isArabic ? "lg:flex-row-reverse text-right" : "lg:flex-row text-left"} lg:-mx-2`}>
          <div className={`space-y-3 lg:w-1/5 lg:px-2 lg:space-y-4 ${isArabic ? "text-right" : "text-left"}`}>
            <div className="space-y-2">
              <p className="font-semibold text-gray-700">{isArabic ? "العلامات التجارية" : "Brands"}</p>
              {productsData.length > 0 && productsData.map((brand) => (
                <div key={brand.id} className="mb-4">
                  <div className={`flex items-center ${isArabic ? "justify-end" : "justify-start"}`}>
                    <label htmlFor={`brand-${brand.id}`} className={`ml-2 text-gray-700 font-semibold ${isArabic ? "ml-0 mr-2" : "order-2 "}`}>
                      {isArabic ? brand.name_ar : brand.name}
                    </label>
                    <input
                      type="checkbox"
                      id={`brand-${brand.id}`}
                      checked={selectedBrands.includes(brand.name)}
                      onChange={() => handleBrandChange(brand.name)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                  </div>
                  <div className={`ml-6 space-y-1 ${isArabic ? "mr-6 ml-0" : ""}`}>
                    {brand.categories.map((category) => (
                      <div key={category.id} className={`flex items-center ${isArabic ? "justify-end" : "justify-start"}`}>
                        <label htmlFor={`category-${category.id}`} className={`ml-2 text-gray-600 ${isArabic ? " ml-0 mr-2" : "order-2"}`}>
                          {isArabic ? category.name_ar : category.name}
                        </label>
                        <input
                          type="checkbox"
                          id={`category-${category.id}`}
                          checked={selectedCategories.includes(category.name)}
                          onChange={() => handleCategoryChange(category.name)}
                          className="form-checkbox h-5 w-5 text-blue-600"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={`mt-6 lg:mt-0 lg:px-2 lg:w-4/5 ${isArabic ? "text-right" : "text-left"}`}>
            <div className={`flex items-center justify-between text-sm tracking-widest uppercase ${isArabic ? "justify-end" : ""}`}>
              {isArabic ? <p className="text-gray-500">منتجات {filteredProducts.length}</p> :
                <p className="text-gray-500">{filteredProducts.length} Items</p>}
            </div>

            <div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
              {filteredProducts.map((product) => (
                <ProductCard2
                  isArabic={isArabic}
                  key={product.id}
                  product={product}
                  onClick={() => handleOpenModal(product)}
                  className="product-card"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {selectedProduct && <SingleProduct isArabic={isArabic} product={selectedProduct} setIsModalOpen={setIsModalOpen} className="modal" />}
      </Modal>
    </section>
  );
};

export default ProductList;
