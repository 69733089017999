import React, { useState, useEffect } from "react";
import { NavLinks } from "../constants/NavConsts";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/Logo.png";
import EgyptSvg from "./EgyptSvg";
import UkSvg from "./UkSvg";
import gsap from "gsap";

const Navbar = ({ isArabic, setIsArabic }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  
  const location = useLocation(); // Get the current location

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isSticky) {
      gsap.to(".navbar", { backgroundColor: "white", duration: 0.3 });
    } else {
      gsap.to(".navbar", { backgroundColor: "transparent", duration: 0.3 });
    }
  }, [isSticky]);

  useEffect(() => {
    const currentLink = NavLinks.find((link) => link.url === location.pathname);
    if (currentLink) {
      setSelectedNavItem(currentLink.id);
    }
  }, [location.pathname]); // Update when the pathname changes

  return (
    <header className={`absolute inset-x-0 top-0 z-[99999] navbar`}>
      <nav
        className={`w-full flex items-center justify-between p-6 lg:px-8 transition-all duration-500 ${
          isArabic && "flex-row"
        } ${isSticky && "fixed top-0 bg-white shadow-md"}`}
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5 z[9999]">
            <span className="sr-only">Ramsis</span>
            <img className="h-12 w-auto" src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="flex lg:hidden z[99999]">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-primarydark z[99999]"
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div
          className={`hidden lg:flex ${
            isArabic && "flex-row-reverse"
          } lg:gap-x-12`}
        >
          {NavLinks.map((link) => (
            <Link
              key={link.id}
              to={link.url}
              className={`text-sm font-semibold leading-6 transition-all duration-500 text-primarydark z[60] border-b-2 pb-1 hover:border-secondary ${
                selectedNavItem === link.id ? "text-secondary border-secondary" : "border-transparent"
              }`}
              onClick={() => setSelectedNavItem(link.id)}
            >
              {isArabic ? link.titleAr : link.title}
            </Link>
          ))}
        </div>
        <div className="hidden lg:gap-4 lg:items-center lg:w-full lg:flex lg:flex-1 lg:justify-end">
          <div
            className="rounded-full self-center"
            onClick={() => setIsArabic((prev) => !prev)}
          >
            {isArabic ? <UkSvg /> : <EgyptSvg />}
          </div>
        </div>
      </nav>
      {/* Mobile menu, show/hide based on menu open state. */}
      {menuOpen && (
        <div className="lg:hidden" role="dialog" aria-modal="true">
          {/* Background backdrop */}
          <div className="fixed inset-0 z-50"></div>
          <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Ramsis</span>
                <img className="h-8 w-auto" src={logo} alt="Logo" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-primarydark"
                onClick={toggleMenu}
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div
                className={`-my-6 divide-y divide-gray-500/10 flex flex-col ${
                  isArabic ? "items-end" : "items-start"
                }`}
              >
                <div className="space-y-2 py-6">
                  {NavLinks.map((link) => (
                    <Link
                      key={link.id}
                      to={link.url}
                      className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                        selectedNavItem === link.id ? "text-secondary" : ""
                      }`}
                      onClick={() => {
                        setSelectedNavItem(link.id);
                        toggleMenu();
                      }}
                    >
                      {isArabic ? link.titleAr : link.title}
                    </Link>
                  ))}
                </div>
                <div className="py-6">
                  <div
                    className="rounded-full self-center"
                    onClick={() => setIsArabic((prev) => !prev)}
                  >
                    {isArabic ? <UkSvg /> : <EgyptSvg />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
