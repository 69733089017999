import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const ProductCard2 = ({ product, onClick, isArabic }) => {
  const [selectedImage, setSelectedImage] = useState(product.images[0].image);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imageRef = useRef(null);

  const uniqueSizes = [...new Set(product.variations.map(variation => variation.size))];
  const uniqueColors = [...new Set(product.variations.map(variation => variation.color))];

  const handleColorClick = (color) => {
    const selectedVariation = product.variations.find(variation => variation.color === color);
    if (selectedVariation && selectedVariation.image) {
      setSelectedImage(selectedVariation.image);
      setIsImageLoaded(false); // Reset the loading state when a new image is selected
    } else {
      setSelectedImage(product.images[0].image);
      setIsImageLoaded(false);
    }
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
    if (imageRef.current) {
      gsap.fromTo(imageRef.current, 
        { opacity: 0, scale: 0.9 }, 
        { opacity: 1, scale: 1, duration: 0.5, ease: 'power2.out' }
      );
    }
  };

  useEffect(() => {
    if (selectedImage) {
      const img = new Image();
      img.src = selectedImage;
      img.onload = handleImageLoad;
    }
  }, [selectedImage]);

  return (
    <div className="max-w-sm bg-white shadow-md rounded-lg flex flex-col">
      
      <div className='rounded-t-lg p-8 mb-14 max-h-[300px] min-h-[300px] w-full self-center flex flex-col'>
        {!isImageLoaded && (
          <div className="bg-gray-300 animate-pulse rounded-lg p-8 m-8 max-h-[200px] self-center object-fill"></div>
        )}
        <img 
          ref={imageRef}
          className={`cursor-pointer rounded-t-lg p-8 max-h-[200px] self-center object-fill ${isImageLoaded ? '' : 'hidden'}`} 
          src={selectedImage} 
          alt={isArabic ? product.name_ar : product.name}
          loading="lazy"
          onClick={onClick}
        />
        {product.images.length > 1 && (
          <div className="flex w-full">
            {product.images.map((image, index) => (
              <div 
                key={index} 
                className="cursor-pointer" 
                style={{ width: `${100 / product.images.length}%` }}
                onClick={() => setSelectedImage(image.image)}
              >
                <img 
                  src={image.image} 
                  alt={`Thumbnail ${index + 1}`} 
                  className="rounded-t-lg p-2 max-h-[50px] self-center object-fill"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        )}
      </div>
      
      <div className="px-5 pb-5">
        <a href="#">
          <h3 className="text-gray-900 font-semibold text-xl tracking-tight ">
            {isArabic ? product.name_ar : product.name}
          </h3>
        </a>
        <div className="flex items-center text-gray-500 mt-2.5 mb-5">
          {isArabic ? product.short_description_ar : product.short_description}
        </div>
        
        {uniqueColors.length > 0 && (
          <div>
            <div className="size mt-4">
              <h3 className="text-gray-900 font-semibold text-lg ">Size :</h3>
              {uniqueSizes.map((size, index) => (
                <span key={index} className="ml-2 text-gray-800 ">
                  {size.toUpperCase()}
                </span>
              ))}
            </div>
            <div className="color mt-4">
              <h3 className="text-gray-900 font-semibold text-lg ">Color :</h3>
              {uniqueColors.map((color, index) => (
                <span
                  key={index}
                  style={{ backgroundColor: color, borderColor: color }}
                  className="inline-block w-6 h-6 mr-2 mt-2 rounded-full border cursor-pointer"
                  onClick={() => handleColorClick(color)}
                ></span>
              ))}
            </div>
          </div>
        )}
        <div className="flex items-center justify-between">
          <div onClick={onClick} className="text-white bg-primarydark hover:bg-secondary cursor-pointer focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">{isArabic?'المزيد':'SEE MORE'}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard2;
